import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

export interface draggableProps {
   id: string;
   disabled: boolean;
   children: unknown;
}

function Draggable(props) {
   const { attributes, listeners, setNodeRef, transform } = useDraggable({
      id: props.id,
      disabled: props.disabled,
   });
   const style = {
      // Outputs `translate3d(x, y, 0)`
      transform: CSS.Translate.toString(transform),
      padding: '40px',
      margin: '10px',
   };

   if (props.disabled) {
      return <></>;
   }

   return (
      <button
         disabled={props.disabled}
         ref={setNodeRef}
         style={style}
         {...listeners}
         {...attributes}
      >
         {props.children}
      </button>
   );
}

export default Draggable;
