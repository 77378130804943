import { useEffect, useState } from 'react';

import { Car } from '../components/plateDebate/model.ts';
import getJson from '../utils/getJson.ts';

const GetPlatesJson = (): Car[] => {
   const [data, setData] = useState<Car[]>();

   const fetchData = async () => {
      try {
         const response = await getJson('plates.json', 'json');

         setData(response);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return data as Car[];
};

export default GetPlatesJson;
