import { useDroppable } from '@dnd-kit/core';
import React from 'react';

function Droppable(props) {
   const { isOver, setNodeRef } = useDroppable({
      id: props.id,
   });
   const style = {
      opacity: isOver ? 1 : 0.5,
      padding: '40px',
      margin: '10px',
      backgroundColor: isOver ? 'gray' : 'lightgray',
   };

   return (
      <div ref={setNodeRef} style={style}>
         {props.children}
      </div>
   );
}

export default Droppable;
