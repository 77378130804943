import { useEffect, useState } from 'react';

import Subreddit from '../models/Subreddit.ts';
import getJson from '../utils/getJson.ts';

const GetSubredditJson = (): Subreddit[] => {
   const [data, setData] = useState<Subreddit[]>();

   const fetchData = async () => {
      try {
         const response = await getJson('/prefixed_names_and_subs_250k.json', 'json');

         setData(response);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return data as Subreddit[];
};

export default GetSubredditJson;
