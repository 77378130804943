import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useSearchParams } from 'react-router-dom';
import GetSubredditJson from '../../hooks/getSubredditJson.ts';

function Random(sortFromUrl?: string | undefined, overrideDepth?: number | undefined) {
   const [searchParams] = useSearchParams();
   const location = useLocation();
   const subreddits = GetSubredditJson();

   useEffect(() => {
      if (
         !location.pathname.toLowerCase().includes('/reddit/random') &&
         !location.pathname.toLowerCase().includes('/randomreddit')
      )
         return;

      if (location.pathname.toLowerCase().includes('/reddit/random') && sortFromUrl !== undefined) {
         if (!location.pathname.toLowerCase().includes(sortFromUrl)) return;
      }

      if (
         location.pathname.toLowerCase().includes('/reddit/random/top') ||
         location.pathname.toLowerCase().includes('/reddit/random/hot')
      ) {
         if (sortFromUrl === undefined) return;
      }

      if (subreddits === undefined || subreddits.length === undefined) return;

      // Get params like ?t=year&feedViewType=cardView`;
      let depthParam = overrideDepth ?? 0;
      let useOld = false;
      const otherParams = new URLSearchParams();

      searchParams.forEach((value, name) => {
         if (name === 'depth') {
            depthParam = parseInt(searchParams.get('depth') ?? '');
         } else if (name === 'old') {
            useOld = true;
         } else {
            otherParams.append(name, value);
         }
      });

      // Get subreddit
      let max = subreddits.length;
      if (depthParam !== 0 && depthParam < subreddits.length) {
         max = depthParam;
      }

      const randomIndex = Math.floor(Math.random() * max);
      const subreddit = subreddits[randomIndex].name;

      // Get sort
      const sort = sortFromUrl !== undefined ? `/${sortFromUrl}` : '';
      const queryString = otherParams.size > 0 ? `?${otherParams.toString()}` : '';
      const prefix = useOld ? 'old.' : 'www.';
      const url = `https://${prefix}reddit.com/${subreddit}${sort}${queryString}`;

      console.log(`url: ${url} depth: ${max} index: ${randomIndex}`);
      window.location.href = url;
   });

   return (
      <div>
         <Helmet>
            <title>Opening random subreddit...</title>
         </Helmet>
         Opening random subreddit...
      </div>
   );
}

export default Random;
