import { DndContext } from '@dnd-kit/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import GetPlatesJson from '../../hooks/getPlatesJson.ts';
import Draggable from './draggable.tsx';
import Droppable from './droppable.tsx';
import { Answers, Questions } from './model.ts';

function PlateDebate() {
   const [answers, setAnswers] = useState<Answers | undefined>(undefined);
   const [questions, setQuestions] = useState<Questions | undefined>(undefined);

   const data = GetPlatesJson();

   if (data === undefined) {
      return <>Loading...</>;
   }

   const cars = [
      data[Math.floor(Math.random() * data.length)],
      data[Math.floor(Math.random() * data.length)],
      data[Math.floor(Math.random() * data.length)],
   ];

   function submitAnswer(answers: Answers, plate: string, model: string) {
      if (answers.Answer1.Plate === plate && answers.Answer1.Model === model) {
         answers.Answer1.IsSolved = true;
         answers.Answer1.IsPlateDisabled = true;
         return;
      }

      if (answers.Answer2.Plate === plate && answers.Answer2.Model === model) {
         answers.Answer2.IsSolved = true;
         answers.Answer2.IsPlateDisabled = true;
         return;
      }

      if (answers.Answer3.Plate === plate && answers.Answer3.Model === model) {
         answers.Answer3.IsSolved = true;
         answers.Answer3.IsPlateDisabled = true;
         return;
      }

      if (answers.Answer1.Plate === plate) {
         answers.Answer1.IsPlateDisabled = true;
      }

      if (answers.Answer2.Plate === plate) {
         answers.Answer2.IsPlateDisabled = true;
      }

      if (answers.Answer3.Plate === plate) {
         answers.Answer3.IsPlateDisabled = true;
      }
   }

   function getModelSolved(answers: Answers, model: string): boolean {
      if (answers.Answer1.Model === model) {
         return answers.Answer1.IsSolved;
      }

      if (answers.Answer2.Model === model) {
         return answers.Answer2.IsSolved;
      }

      if (answers.Answer3.Model === model) {
         return answers.Answer3.IsSolved;
      }

      return false;
   }

   function getPlateDisabled(answers: Answers, plate: string): boolean {
      if (answers.Answer1.Plate === plate) {
         return answers.Answer1.IsPlateDisabled;
      }

      if (answers.Answer2.Plate === plate) {
         return answers.Answer2.IsPlateDisabled;
      }

      if (answers.Answer3.Plate === plate) {
         return answers.Answer3.IsPlateDisabled;
      }

      return false;
   }

   function handleDragEnd(event) {
      if (event.over === null) {
         return;
      }

      const idDict = {
         draggableQuestion1Plate: questions?.Question1.Plate,
         draggableQuestion2Plate: questions?.Question2.Plate,
         draggableQuestion3Plate: questions?.Question3.Plate,
         droppableQuestion1Model: questions?.Question1.Model,
         droppableQuestion2Model: questions?.Question2.Model,
         droppableQuestion3Model: questions?.Question3.Model,
      };

      const plate = idDict[event.active.id];
      const model = idDict[event.over.id];

      if (answers !== undefined) {
         submitAnswer(answers, plate, model);

         setAnswers({ ...answers });
      }
   }

   if (answers === undefined) {
      setAnswers(new Answers(cars));
   }

   if (questions === undefined) {
      setQuestions(new Questions(cars));
   }

   if (answers === undefined || questions === undefined) {
      return <>Loading...</>;
   }

   const draggable1 = (
      <Draggable
         disabled={getPlateDisabled(answers, questions?.Question1.Plate)}
         id='draggableQuestion1Plate'
      >
         {questions?.Question1.Plate}
      </Draggable>
   );
   const draggable2 = (
      <Draggable
         disabled={getPlateDisabled(answers, questions?.Question2.Plate)}
         id='draggableQuestion2Plate'
      >
         {questions?.Question2.Plate}
      </Draggable>
   );
   const draggable3 = (
      <Draggable
         disabled={getPlateDisabled(answers, questions?.Question3.Plate)}
         id='draggableQuestion3Plate'
      >
         {questions?.Question3.Plate}
      </Draggable>
   );

   return (
      <>
         <Helmet>
            <title>0800 PLATE DEBATE</title>
         </Helmet>
         <div style={{ margin: '10px', fontWeight: 'bold', color: 'purple' }}>
            0800 PLATE DEBATE M8 8/8
         </div>
         <div style={{ margin: '10px' }}>
            Instructions: Drag and drop the personalized plate to the correct car, using as much
            judgement as possible
         </div>
         <DndContext onDragEnd={handleDragEnd}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               {draggable1}
               {draggable2}
               {draggable3}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div>
                  <Droppable id='droppableQuestion1Model'>
                     <div>
                        <div>{questions?.Question1.Year}</div>
                        <div style={{ fontWeight: 'bold' }}>{questions?.Question1.Model}</div>
                        <div>{questions?.Question1.SubModel}</div>
                        <div>{questions?.Question1.Colour}</div>
                     </div>
                  </Droppable>
                  <div style={{ marginLeft: '10px' }}>
                     Is correct:{' '}
                     {getModelSolved(answers, questions?.Question1.Model) ? (
                        <span style={{ fontWeight: 'bold', color: 'green' }}>Yup!</span>
                     ) : (
                        'Naht yet'
                     )}
                  </div>
               </div>

               <div>
                  <Droppable id='droppableQuestion2Model'>
                     <div>
                        <div>{questions?.Question2.Year}</div>
                        <div style={{ fontWeight: 'bold' }}>{questions?.Question2.Model}</div>
                        <div>{questions?.Question2.SubModel}</div>
                        <div>{questions?.Question2.Colour}</div>
                     </div>
                  </Droppable>
                  <div style={{ marginLeft: '10px' }}>
                     Is correct:{' '}
                     {getModelSolved(answers, questions?.Question2.Model) ? (
                        <span style={{ fontWeight: 'bold', color: 'green' }}>Yup!</span>
                     ) : (
                        'Naht yet'
                     )}
                  </div>
               </div>

               <div>
                  <Droppable id='droppableQuestion3Model'>
                     <div>
                        <div>{questions?.Question3.Year}</div>
                        <div style={{ fontWeight: 'bold' }}>{questions?.Question3.Model}</div>
                        <div>{questions?.Question3.SubModel}</div>
                        <div>{questions?.Question3.Colour}</div>
                     </div>
                  </Droppable>
                  <div style={{ marginLeft: '10px' }}>
                     Is correct:{' '}
                     {getModelSolved(answers, questions?.Question3.Model) ? (
                        <span style={{ fontWeight: 'bold', color: 'green' }}>Yup!</span>
                     ) : (
                        'Naht yet'
                     )}
                  </div>
               </div>
            </div>
         </DndContext>
         {answers.Answer1.IsPlateDisabled &&
            answers.Answer2.IsPlateDisabled &&
            answers.Answer3.IsPlateDisabled && (
               <div style={{ marginLeft: '10px', marginTop: '40px', color: 'red' }}>
                  Fully donzo bro gutz if you lost get good lmao
               </div>
            )}
      </>
   );
}

export default PlateDebate;
