const shuffle = (array: number[]) => {
   let currentIndex = array.length;

   // While there remain elements to shuffle...
   while (currentIndex !== 0) {
      // Pick a remaining element...
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
   }
};

export interface Car {
   plate: string;
   model: string;
   submodel?: string | undefined;
   year?: string | undefined;
   colour?: string | undefined;
   photo?: string | undefined;
}

export interface Answer {
   Plate: string;
   Model: string;
   IsSolved: boolean;
   IsPlateDisabled: boolean;
}

export class Answers {
   constructor(cars: Car[]) {
      this.Answer1 = {
         Plate: cars[0].plate,
         Model: cars[0].model,
         IsSolved: false,
         IsPlateDisabled: false,
      };
      this.Answer2 = {
         Plate: cars[1].plate,
         Model: cars[1].model,
         IsSolved: false,
         IsPlateDisabled: false,
      };
      this.Answer3 = {
         Plate: cars[2].plate,
         Model: cars[2].model,
         IsSolved: false,
         IsPlateDisabled: false,
      };
   }

   Answer1: Answer;
   Answer2: Answer;
   Answer3: Answer;
}

export interface Question {
   Plate: string;
   Model: string;
   IsPlateDisabled: boolean;
   Colour: string | undefined;
   SubModel: string | undefined;
   Year: string | undefined;
}

export class Questions {
   Question1: Question;
   Question2: Question;
   Question3: Question;

   constructor(cars: Car[]) {
      const plateIndexes = [0, 1, 2];
      const modelIndexes = [0, 1, 2];

      shuffle(plateIndexes);
      shuffle(modelIndexes);

      this.Question1 = {
         Plate: cars[plateIndexes[0]].plate,
         Model: cars[modelIndexes[0]].model,
         IsPlateDisabled: false,
         Colour: cars[modelIndexes[0]].colour,
         SubModel: cars[modelIndexes[0]].submodel,
         Year: cars[modelIndexes[0]].year,
      };
      this.Question2 = {
         Plate: cars[plateIndexes[1]].plate,
         Model: cars[modelIndexes[1]].model,
         IsPlateDisabled: false,
         Colour: cars[modelIndexes[1]].colour,
         SubModel: cars[modelIndexes[1]].submodel,
         Year: cars[modelIndexes[1]].year,
      };
      this.Question3 = {
         Plate: cars[plateIndexes[2]].plate,
         Model: cars[modelIndexes[2]].model,
         IsPlateDisabled: false,
         Colour: cars[modelIndexes[2]].colour,
         SubModel: cars[modelIndexes[2]].submodel,
         Year: cars[modelIndexes[2]].year,
      };
   }
}
