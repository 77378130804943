import { useEffect, useState } from 'react';

import Episode from '../models/Episode.ts';
import getJson from '../utils/getJson.ts';

const GetComeDineWithMeJson = (): Episode[] => {
   const [data, setData] = useState<Episode[]>();

   const fetchData = async () => {
      try {
         const response = await getJson('/comedinewithmejson_withdates.json', 'json');

         setData(response);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return data as Episode[];
};

export default GetComeDineWithMeJson;
